@font-face {
  font-style: bold;
  src: url("../assets/fonts/intersemibold400.ttf");
  font-family: "Inter";
  font-weight: 400;
}

@font-face {
  font-style: normal;
  src: url("../assets/fonts/interregular400.ttf");
  font-family: "Inter";
  font-weight: 400;
}

@font-face {
  font-style: bold;
  src: url("../assets/fonts/josefinsansjosefinsansromanbold700.ttf");
  font-family: "Josefin Sans";
  font-weight: 700;
}

@font-face {
  font-style: bold;
  src: url("../assets/fonts/josefinsansjosefinsansromanlight300.ttf");
  font-family: "Josefin Sans";
  font-weight: 300;
}

@font-face {
  font-style: bold;
  src: url("../assets/fonts/intermedium500.ttf");
  font-family: "Inter";
  font-weight: 500;
}
